/**
 * @license
 * Copyright 2017 Google Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Path } from '../util/Path';
import { Operation, OperationSource, OperationType } from './Operation';

/**
 * @param {!OperationSource} source
 * @param {!Path} path
 * @constructor
 * @implements {Operation}
 */
export class ListenComplete implements Operation {
 /** @inheritDoc */
 type = OperationType.LISTEN_COMPLETE;

 constructor(public source: OperationSource, public path: Path) {}

 operationForChild(childName: string): ListenComplete {
 if (this.path.isEmpty()) {
 return new ListenComplete(this.source, Path.Empty);
 } else {
 return new ListenComplete(this.source, this.path.popFront());
 }
 }
}
